import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Markdown from '@base/parts/markdown'
import Button from '@base/parts/button'

import StyledButton from '@elements/styled-button'
import Loader from '@elements/loader'

const NotFound = () => {
  const data = {
    pageData: {
      title: 'ページが見つかりません',
      localizations: null,
      disableAgeGate: true,
      disableBuyGameSection: true
    }
  }

  return (
    <>
      <div className='flex justify-center items-center flex-col h-screen'>
        <h2 className={`leading-none w-full font-display`}>
          <svg className="w-full h-40">
            <text x="50%" y="40%" textAnchor="middle">
              <Markdown
                children={data.pageData.title}
                type="svg"
              />
            </text>
          </svg>
        </h2>
        <p class="text-base font-bold text-white lg:text-center mb-20 px-8 lg:px-0">
          <span>お探しのページは存在しないか、移動されています。</span>
        </p>
        <Button
          link={'/ja/'}
          trigger="internalLink"
          size={'button-styled-inverted'}
          className={`relative`}
        >
          <StyledButton style={'inverted'} />
          <span className="font-displayCondensed">ホームページに戻ります</span>
        </Button>
        <StaticImage
          src='../../assets/img/content-background.jpg'
          alt=""
          placeholder="blurred"
          className="w-full h-full object-cover object-top min-h-keyart z-under gatsby-absolute"
        />
      </div>
      <Loader />
    </>
  )
}

export default NotFound

export { Head } from '@base/layout/head'